.dialog-list[data-v-a90da318] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.dialog-list .cus-option-configable[data-v-a90da318] {
    width: calc(100% - 50px) !important;
}
.dialog-list .cus-option-configable[data-v-a90da318] .el-input__inner {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-right: 0px;
}
.dialog-list .option-config-icon[data-v-a90da318] {
    width: 48px;
    height: 36px;
    text-align: center;
    border: 1px solid #dcdfe6;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;
}
.dialog-list[data-v-a90da318] .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #d1dfd5;
}
[data-v-a90da318] .cus-formdata-dialog {
  height: 100%;
}
[data-v-a90da318] .cus-formdata-dialog .el-dialog__body {
    height: 100%;
}
[data-v-a90da318] .table-dialog .el-dialog__body {
  height: 100%;
}
[data-v-a90da318] .table-dialog .el-dialog__body .search-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 50px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
[data-v-a90da318] .table-dialog .el-dialog__body .search-wrapper .search-content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      width: calc(100% - 120px);
}
[data-v-a90da318] .table-dialog .el-dialog__body .search-wrapper .search-content .search-item {
        width: calc(25% - 10px);
        margin-right: 10px;
        margin-bottom: 10px;
}
[data-v-a90da318] .table-dialog .el-dialog__body .search-wrapper .search-btn {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 120px;
      margin-bottom: 10px;
}
